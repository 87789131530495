import AppContext from "../AppContext";
//import {Link} from "react-router-dom";
import React, {useContext} from "react";
import * as Go from "./Go";
import DocLink from "./DocLink";

// pass props to (type,id,section,hash) or just section for doc in context
export default function PageLink(props) {
    const doc = useContext(AppContext);
    const page = undefined !== props.page && props.page && props.page[0] === '/'? props.page.substr(1): props.page;
    const to = props.page? Go.to(page,null,null,props.hash? props.hash: null)
        : props.to? props.to
        : Go.to(doc.fileType,doc.fileId,props.section);

    const pages = [
        "login",
        "about",
        "faq",
        "docs",
        "support",
        "privacy",
        "migration",
        "attributions",
        "library"
    ];

    const organizer = [
        'unit-org-2',
        'cause-effect',
        'question-exp',
        'comparison',
        'cera'
    ];

    const hash = undefined !== props.hash && props.hash? props.hash: "";

    if (undefined !== to.state && to.state.type && pages.includes(to.state.type)) {
        if ("privacy" === to.state.type)
            return <a className={props.className} href={"/privacy.html"} >
                {props.children}
            </a>;

        return <a {...props} href={'/'+to.state.type+hash} >
            {props.children}
        </a>;
    }
    if (to.state.type && organizer.includes(to.state.type)) {
        return <DocLink to={to} hash={hash}>
            {props.children}
        </DocLink>
    }
    return (
            <a href={to.pathname+hash} className={props.className? props.className: null}  >
                {props.children}
            </a>
        );
    }
